<template>
  <el-dialog v-model="show" :show-close="false" :close-on-press-escape="false" :width="'407px'" :custom-class="'dialog-no-title dialog-align-center dialog-full-transparent'">
    <div class="ticket-receive-pop" :style="{ '--bkImage': `url('${imageUrl}')`}">
      <p class="word">探角精英版限时体验特权</p>
      <img src="@/assets/images/Tan/pop/ticket/collected.png" class="collected"/>
      <div class="ticket-line" v-for="item in ticketShow" :key="item.couponCode">
        <div class="left" v-if="item.couponType === 1">
          {{ item.couponContent }}<span>折</span>
        </div>
        <div class="left" v-else-if="item.couponType === 2">
          {{ item.couponContent }}<span>元</span>
        </div>
        <div class="left" v-else-if="item.couponType === 3">
          {{ item.couponContent }}<span>天</span>
        </div>
        <div class="right">
          <p class="p-0 text-ol-ell">{{ item.couponName }}</p>
          <p class="p-1 text-ol-ell" v-if="item.couponType === 1">直降金额<span>{{ calculateMinusPrice(item) }}</span>元</p>
          <p class="p-1 text-ol-ell" v-if="item.couponType === 2">直降金额<span>{{ item.couponContent }}</span>元</p>
          <p class="p-1 text-ol-ell" v-if="item.couponType === 3">有效期至：<span>{{ getEffectiveDay(item.effectiveDay) }}</span></p>
        </div>
      </div>
      <div class="ticket-receive-button-line">
        <div class="click-area" @click="toResourceCenter">
        </div>
      </div>
      <div class="close-line">
        <img src="@/assets/images/Tan/pop/ticket/close.png" @click="closePop"/>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTanProducts } from "@/api/Tan/product";
import { receiveTanTicket,useTanTicketByCode } from "@/api/Tan/ticket";
import { ElMessage, } from 'element-plus';


export default{
  data(){
    return {
      show: false,
      products: [],
      loading: false,
    }
  },
  computed:{
    ...mapGetters([
      "TanTicketReceivePopShow",
    ]),
    imageUrl(){
      if(this.TanTicketReceivePopShow){
        return JSON.parse(this.TanTicketReceivePopShow).img
      }
      else{
        return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/10/11/1731292457151827'
      }
    },
    ticketShow(){
      if(this.ticketListLength > 0){
        return this.ticketList.slice(0,1)
      }
      return []
    },
    ticketListLength(){
      return this.ticketList.length
    },
    ticketList(){
      if(this.TanTicketReceivePopShow){
        return JSON.parse(this.TanTicketReceivePopShow).tickets
      }
      else{
        return []
      }
    },
  },
  watch:{
    ticketListLength(val){
      if(val > 0){
        this.receiveUseTicket()
      }
    },
    TanTicketReceivePopShow(val){
      if(val) this.show = true
      else this.show = false
    },
    show(val){
      if(!val){
        this.$store.dispatch('Tan/setTicketReceivePopShow','')
      }
    },
  },
  mounted(){
    this.getProducts()
  },
  methods:{
    receiveUseTicket(){
      receiveTanTicket(this.ticketList.map(item=>item.couponCode)).then(async ()=>{
        // * 先全部领取 只使用第一张
        // for (let index = 0; index < this.ticketList.length; index++) {
        //   const element = this.ticketList[index];
        //   if(element.couponType !== 3){
        //     continue
        //   }
        // }
        const element = this.ticketList.find((item)=>item.couponType === 3);
        if(element){
          try {
            await useTanTicketByCode(element.couponCode)
            console.log(element.couponCode,'使用成功');
          } catch (error) {
            console.log(element.couponCode,'使用失败:',error.message);
          }
          this.$store.dispatch('Tan/setTicketStatusChangeByUse')  // * 领取后更新VIP状态
        }
      })
    },
    closePop(){
      this.show = false
    },
    getEffectiveDay(num){
      let n = Number(num)
      // 获取当前日期
      let currentDate = new Date();
      // 计算7天后的日期
      currentDate.setDate(currentDate.getDate() + n);
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      let day = currentDate.getDate();
      return `${year}.${month < 10 ? '0' + month : month}.${day < 10 ? '0' + day : day}`
    },
    toResourceCenter(){
      this.$router.push('/resource-center')
      this.show = false
    },
    getProducts(){
      getTanProducts().then(res=>{
        this.products = res.data.result
      })
    },
    calculateMinusPrice(item){
      if(!item.vipType){
        return '-'
      }
      const product = this.products.find(jtem=>jtem.vipType === item.vipType)
      let price = 0
      if(!product.extendData) price = product.salePrice
      else{
        if(item.vipType === 12){
          price = Math.round((product.salePrice + product.extendData.eachCountPrice * product.extendData.vipMinCount + product.extendData.subscribeMinCount * product.extendData.eachSubscribePrice) * 100) / 100
        }
        else{
          price = Math.round((product.salePrice + product.extendData.eachCountPrice * product.extendData.vipMinCount) * 100) / 100
        }
      }
      return Math.round((price - price * Number(item.couponContent) / 10) * 100) / 100
    },
    receiveTicket(){
      // 领取优惠券
      if(this.loading) return
      this.loading = true
      receiveTanTicket(this.ticketList.map(item=>item.couponCode)).then(()=>{
        ElMessage({
          message: '兑换成功',
          type: 'success',
          customClass: 'message-custom-no-width message-custom-plain message-custom-16 message-custom-333',
          duration: 5000,
        })
        setTimeout(()=>{
          this.show = false
        },1000)
      }).finally(()=>{
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ticket-receive-pop{
  width: 100%;
  height: 712px;
  box-sizing: border-box;
  padding-top: 250px;
  background-image: var(--bkImage);
  background-size: 100% 640px;
  background-repeat: no-repeat;
  background-position: top center;
  padding-bottom: 72px;
  position: relative;
  .collected{
    position: absolute;
    width: 85px;
    height: 66px;
    right: 38.5px;
    top: 276.5px;
  }
  .word{
    font-weight: 500;
    font-size: 21px;
    color: #B3551F;
    line-height: 21px;
    text-align: center;
    padding-bottom: 40.75px;
  }
  .ticket-line{
    width: 260px;
    height: 74px;
    margin: 0px 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      width: 85px;
      display: flex;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 51px;
      background: linear-gradient(90deg, #FFFFFF 0%, #FFEAAF 100%);
      background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      line-height: 72px;
      margin-top: 10px;
      white-space: nowrap;
      span{
        padding-top: 10px;
        font-size: 18px;
      }
    }
    .right{
      width: calc(100% - 116px);
      .p-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 21px;
        padding-bottom: 7px;
      }
      .p-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        line-height: 15px;
        span{
          color: #ffffff;
        }
      }
    }
  }
  .ticket-receive-button{
    margin: 5px auto 0;
    width: 124px;
    height: 40px;
    background: linear-gradient( 81deg, #FFFCF8 0%, #FDD5AD 100%);
    box-shadow: 0px 2px 4px 0px rgba(159,8,8,0.5);
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #72300F;
  }
  .ticket-receive-button-line{
    width: 100%;
    display: flex;
    justify-content: center;
    .click-area{
      width: 180px;
      height: 46px;
      cursor: pointer;
      margin-top: 160px;
    }
  }
  .close-line{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 82px;
    img{
      width: 38.5px;
      height: 38.5px;
      cursor: pointer;
    }
  }
}
</style>