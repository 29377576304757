const getters = {
    smallScreen: status => status.other.smallScreen,  // 显示小屏幕
    messagePending: status => status.message.messagePending,  // 消息加载
    timChange: status => status.user.timChange, // tim状态修改
    visibility: status => status.user.visibility, // 设置页面可见
    reloadUser: status => status.user.reloadUser, // 重新获取用户信息
    openCreateLive: status => status.user.openCreateLive, // 打开导航栏路演窗口
    navbarBread: status => status.user.navbarBread, // 导航栏面包屑
    firstWindowVisible: status => status.message.firstWindowVisible,  // 初次身份弹窗
    createWindowVisible: status => status.message.createWindowVisible,  // 创建身份弹窗
    updateNavbar: status => status.user.updateNavbar, // 手动更新导航栏
    showDark: status => status.user.showDarkTheme,  // 是否展示暗色主题
    showNewYear: status => status.user.showNewYearTheme,  // 是否展示新年主题
    showMiddle: status => status.user.showMiddle, // 是否展示完整 navbar
    showTop: status => status.user.showTop, // 是否展示完整 navbar
    enterpriseType: status => status.user.enterpriseType, // 用户的企业类型
    popupParameter: status => status.popup.parameter,  // 弹窗参数
    popupStatus: status => status.popup.status, // 弹窗状态监听
    popupType: status => status.popup.type, // 弹窗类型
    otherInfo: status => status.message.otherInfo,  // 其他联系内容
    demandInfo: state => state.message.demandInfo,  // 承接需求详情
    closeServiceWindow: state => state.message.closeServiceWindow,
    openServiceWindow: state => state.message.openServiceWindow,
    showServiceWindow: state => state.message.showServiceWindow,
    systemMessageNum: state => state.message.systemMessageNum,
    shareInfos: state => state.message.shareInfos,
    shareMessage: state => state.message.shareMessage,
    addressLocation: state => state.user.location,
    liveListChange: state => state.live.liveListChange,
    showNavbar: state => state.user.showNavbar, // 是否显示 Navbar
    cover: state => state.post.cover,
    postMedias: state => state.post.postMedias,
    postData: state => state.post.postData,
    postType: state => state.post.postType,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    introduction: state => state.user.introduction,
    id: state => state.user.id,
    phone: state => state.user.phone,
    roles: state => state.user.roles,
    industryId: state => state.user.industryId,
    imEasemobUsername: state => state.user.imEasemobUsername,
    imPassword: state => state.user.imPassword,
    level: state => state.user.level,
    vipEndTime: state => state.user.vipEndTime,
    organizationType: state => state.user.organizationType,
    organizationId: state => state.user.organizationId,
    closed:state => state.user.closed,
    isVip: state => state.user.isVip,
    activeNav:state => state.message.activeNav,
    showFooter:state => state.message.showFooter,
    changeBgByScroll:state => state.message.changeBgByScroll,
    messageCounter: state => state.message.messageCounter,
    imWindowVisible: state => state.message.IMWindowVisible,
    leaveMessageVisible: state => state.message.leaveMessageVisible,  // 留言窗口组件
    leaveMessageClickId: state => state.message.leaveMessageClickId,  // 留言对象ID
    leaveMessageClickInfo: state => state.message.leaveMessageClickInfo,  // 留言对象信息
    leaveMessageUpdate: state => state.message.leaveMessageUpdate,  // 通知更新留言列表
    imDialogVisible: state => state.message.IMDialogVisible,
    labTipVisible: state => state.message.labTipVisible,  // 是否展示定制数字科创中心的弹窗
    imRoleDialogVisible: state => state.message.IMRoleDialogVisible,
    breadcrumbItems: state => state.breadcrumb.items,
    messageTrigger: state => state.message.messageTrigger,
    messageTriggerTitle: state => state.message.messageTriggerTitle,
    messageQuickchat: state => state.message.messageQuickchat,
    messageUnlimited: state => state.message.messageUnlimited,
    serverId: state => state.message.serverId,
    toMemberId: state => state.message.toMemberId,
    messageTriggerServerid: state => state.message.messageTriggerServerid,
    sdkReady: state => state.tim.sdkReady,
    achieveStatus: state => state.G60.achieveStatus,
    demandStatus: state => state.G60.demandStatus,
    corStatus: state => state.G60.corStatus,
    askStatus: state => state.G60.askStatus,
    successStatus: state => state.G60.successStatus,
    expertStatus: state => state.G60.expertStatus,
    questionStatus: state => state.G60.questionStatus,
    vipStatus: state => state.G60.vipStatus,
    vipFormStatus: state => state.G60.vipFormStatus,
    tableStatus: state => state.G60.tableStatus,
    editingUserInfo: state => state.user.editingUserInfo,
    loginStatus: state => state.Tan.loginStatus,
    AILoginStatus: state => state.AI.loginStatus,
    TanInvitePopShow: state => state.Tan.invitePopShow,
    TanVipInfo: state => state.Tan.vipInfo,
    TanVipPopShow: state => state.Tan.vipPopShow,
    TanVipPopOrderInfo: state => state.Tan.vipPopOrderInfo,
    TanQuotationPopShow: state => state.Tan.quotationPopShow,
    TanPurchasePopShow: state => state.Tan.purchasePopShow,
    TanCompanyEditPopShow: state => state.Tan.companyEditPopShow,
    TanPersonalEditPopShow: state => state.Tan.personalEditPopShow,
    TanTeamManagementApply: state => state.Tan.teamManagementApply,
    TanTicketReceivePopShow: state => state.Tan.ticketReceivePopShow,
    TanTicketSelectedInfo: state => state.Tan.ticketSelectedInfo,
    TanTicketStatusChangeByUse: state => state.Tan.ticketStatusChangeByUse,
  }
export default getters