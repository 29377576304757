<template>
  <el-dialog v-model="showWindow" :show-close="false" :close-on-press-escape="false" :width="'660px'" :custom-class="'dialog-no-title dialog-transparent'" :close-on-click-modal="false">
    <div class="company-edit-window">
      <p class="p-0">账号设置</p>
      <img src="@/assets/images/Tan/pop/companyEdit/close.png" class="close" @click="closeEdit" />
      <div class="title-line">
        基本信息
      </div>
      <div class="form-item">
        <div class="item-title">
          头像：
        </div>
        <div class="right">
          <div class="upload" v-if="!form.file.url" v-loading="uploading">
            <el-upload
              action="#"
              :accept="'image/*'"
              v-model:file-list="form.file.files"
              :http-request="handleUpload"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :before-upload="beforeUploadFile"
              class="my-upload"
              :limit="1">
              <div class="upload-container">
                <img src="@/assets/images/Tan/pop/vip/upload.png" />
              </div>
            </el-upload>
          </div>
          <div class="upload" v-else v-loading="uploading">
            <img class="result" :src="form.file.url"/>
            <img class="delete" @click="handleRemove" src="@/assets/images/Tan/pop/vip/delete.png"/>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          姓名：
        </div>
        <div class="right">
          <el-input v-model="form.name" style="width: 340px;" placeholder="请输入姓名" maxlength="20"/>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          职务：
        </div>
        <div class="right">
          <el-select v-model="form.position" placeholder="请选择" style="width: 340px;">
            <el-option
              v-for="(item,index) in positionList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          手机号：
        </div>
        <div class="right">
          <el-input class="phone" v-model="phone" readonly style="width: 340px;" placeholder="请输入姓名" maxlength="50"/>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title cancel">
          账号注销：
          <p class="tip">注销不可恢复，请谨慎操作</p>
        </div>
        <div class="right">
          <button class="off" @click="cancelAccount">注销</button>
        </div>
      </div>
      <div class="buttons">
        <div class="cancel" @click="closeEdit">
          取消
        </div>
        <div class="submit" @click="submit" v-loading="loading">
          提交
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { uploadTypeImage,cosByUploadType, } from "@/api/upload";
import { bucketMap,staticPath } from "@/utils/consts";
import { ElMessage, ElMessageBox } from 'element-plus';
import { randomFilename } from "@/utils/time";
import { TanCancelAccount,editTanMemberInfo } from "@/api/Tan/member";
import { getTanId,getTanLocalInfo,setTanLocalInfo } from "@/utils/Tan/login";
import { getSelectFatherSonByType } from "@/api/config";

export default{
  data(){
    return{
      showWindow: false,
      uploading: false,
      loading: false,
      form:{
        file: {
          url: '',
          files: [],
        },
        name: '',
        position: '',
      },
      phone: '',
      positionList: [],
    }
  },
  computed:{
    ...mapGetters([
      "TanPersonalEditPopShow",
    ]),
  },
  watch:{
    TanPersonalEditPopShow(val){
      this.showWindow = val
      if(val){
        const info = getTanLocalInfo()?.tjInfo
        let img = ''
        if(info.img){
          img = info.img.includes('http') ? info.img : staticPath + info.img
        }
        this.form.file.url = img
        this.form.name = info.name
        this.form.position = info.duties
        this.phone = getTanLocalInfo()?.tjInfo.phone
      }
    },
    showWindow(val){
      if(val){
        this.getSelection()
      }
    },
  },
  methods:{
    getSelection(){
      getSelectFatherSonByType(10034).then(res=>{
        this.positionList = res.data.map(item=>item.val)
      })
    },
    submit(){
      if(!this.form.name){
        ElMessage.warning('请填写您的姓名')
        return
      }
      this.loading = true
      editTanMemberInfo({
        "duties": this.form.position,
        "img": this.form.file.url,
        "memberId": getTanId(),
        "memberName": this.form.name,
      }).then(res=>{
        if(res.data){
          ElMessage.success('修改成功')
          let info = getTanLocalInfo()
          info.tjInfo.duties = this.form.position
          info.tjInfo.img = this.form.file.url
          info.tjInfo.name = this.form.name
          setTanLocalInfo(info) // 修改存入缓存
          this.closeEdit()
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    cancelAccount(){
      ElMessageBox.confirm('账号注销后不可恢复，请谨慎操作！','确定注销？', {
        confirmButtonText: '注销',
        cancelButtonText: '取消',
        lockScroll: false,  // 防止页面振动
        type: 'error'
      }).then(()=>{
        TanCancelAccount(getTanId()).then(res=>{
          if(res.data){
            // 成功
            this.$router.push('/identity')
            this.$store.dispatch('Tan/setPersonalEditShow',false)
          }
        }).catch(err=>{
          console.log(err,'注销错误');
        })
      })
    },
    beforeUploadFile(file){
      // 上传文件
      if(file.size > 5 * 1024 * 1024){
        // 大小判断 5M
        ElMessage.warning('上传图片大小过大，不能超过5M!')
        return false
      }
    },
    handleExceed(){
      ElMessage.warning('最多只能上传1个图片')
    },
    handleRemove(){
      this.form.file.files = []
      this.form.file.url = ''
    },
    closeEdit(){
      this.$store.dispatch('Tan/setPersonalEditShow',false)
    },
    handleUpload(val){
      // 先登录
      this.directUploadToTen(val.file).then(res=>{
        this.form.file.url = 'https://' + res.Location
        this.form.file.files = [{
          name: val.file.name,
          url: 'https://' + res.Location,
        },]
      })
    },
    directUploadToTen(file){
      let self = this
      let cos = cosByUploadType(uploadTypeImage)
      let bucket = bucketMap.image
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket,
            Region: "ap-shanghai",
            Key: randomFilename(),
            StorageClass: "STANDARD",
            Body: file,
            onProgress: function (progressData) {
              const percentage = parseInt(progressData.percent * 100);
              if (percentage === 100){
                self.uploading = false;
                ElMessage.success('上传成功!')
              }
              else self.uploading = true
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.company-edit-window{
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  padding-top: 21px;
  padding-bottom: 47px;
  position: relative;
  .close{
    width: 14px;
    height: 14px;
    position: absolute;
    right: 23px;
    cursor: pointer;
    top: 23px;
  }
  .p-0{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    padding-left: 22px;
    padding-bottom: 13px;
  }
  .title-line{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #111111;
    width: 100%;
    background: #EFF6FF;
    box-sizing: border-box;
    border-left: 4px solid $--color-theme;
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    margin-bottom: 20px;
  }
  .form-item{
    width: 100%;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    .cancel{
      padding-top: 4px !important;
    }
    .item-title{
      width: 98px;
      height: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #111111;
      line-height: 14px;
      text-align: right;
      padding-top: 13px;
      position: relative;
      .tip{
        font-size: 12px;
        color: #999999;
        margin-top: 2px;
        white-space: nowrap;
        position: absolute;
        left: -55px;
      }
    }
    .right{
      width: 340px;
      .phone{
        :deep(.el-input__inner){
          border: none;
        }
      }
      .off{
        width: 65px;
        height: 32px;
        background: #ED0900;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        border: none;
      }
    }
    .upload{
      width: 120px;
      height: 70px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      .result{
        width: 120px;
        height: 70px;
      }
      .delete{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-color: #999999ca;
        padding: 3px;
        border-top-right-radius: 10px;
      }
      .upload-container{
        width: 120px;
        height: 70px;
        background: #F4F7FD;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img{
          width: 46px;
          height: 40px;
        }
      }
    }
  }
  .buttons{
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .cancel{
      width: 130px;
      height: 32px;
      background: #C6CAD3;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      margin: 0 10px;
    }
    .submit{
      margin: 0 10px;
      width: 130px;
      height: 32px;
      background: $--color-theme;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
.no-wrap{
  :deep(.el-select__tags) {
    white-space: nowrap;
    overflow: hidden;
  }
  :deep(.el-cascader__tags){
    flex-wrap: nowrap !important;
    overflow: hidden;
  }
}
</style>