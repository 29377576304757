import axios from "axios";
import store from "../store"
import {
  requestVersion,
  decryptByDES,
  talentProjectSystemPage,
  baseURL
} from "@/utils/consts";
import { removeUserInfo } from '@/utils/auth'
import { G60site,TanSite,PostLineSite,AISite } from "@/router/index";
import { getRefreshToken,setNewToken,getTPSToken,removeLocalInfo } from "./talent-project-system/login";
import { getG60RefreshToken,setG60NewToken,getG60Token,removeG60LocalInfo } from "./G60/login";
import { getTanRefreshToken,setTanNewToken,getTanToken,removeTanLocalInfo } from "./Tan/login";
import { getAIRefreshToken,setAINewToken,getAIToken,removeAILocalInfo } from "./AI/login";
import { getPLToken,getPLRefreshToken,setPLNewToken,removePLLocalInfo } from "./post-line/login";
import { ElMessage } from "element-plus";
import { refreshToken } from '@/api/user'
import { setCookie } from "@/utils/Cookies/index";

const isLocal = false
export const baseRequest = isLocal ? '/api' : baseURL

const noTokenApi = ['/tj/member/invite/decrypt','/commercial/getAllCommercial']
const LSHTokenApi = ['/lsh/login','/lsh/applyFor']

function msgError(arg) {
  if(arg === 'IM组群解除失败' || arg === 'VIP专属功能' || arg === '查询用户个人名片失败,请稍候重试') return
  else if(arg === '预删除的记录不存在，请勿重复删除'){
    // 重复取消关注
    arg = '无法重复取消关注!'
  }
  else if(arg === '优惠券不存在') return
  else if(arg === '优惠券状态不正确') return
  else if(arg === '优惠券已领取') return
  if(arg === '用户ID不能为空') return
  if(arg === '请绑定手机！' || arg === '请重新扫码！'){
    ElMessage.warning(arg)
    return
  }
  if(arg == '没有查询到个人信息'){
    return
  }
  if(window.location.href.indexOf('/user-center') != -1 && arg === '没有查询到个人信息') return
  if(arg !== "需求ID不能为空" && arg !== "接收人ID不能为空" && arg !== "该动态已删除" && arg !== "服务调用方权限不足错误" && arg!== '该路演间不存在' && arg !== '没有查询到个人信息'){
    if(arg === '点过赞不可重复点赞'){
      arg = '重复点赞太快，请重新点击!'
    }
    if(arg == '路演id不存在'){
      return
    }

    ElMessage.error(arg)
  }
  else if(arg === '没有查询到个人信息'){  // id错误
    store.dispatch('message/hideIMWindow')
    ElMessage.warning(arg)
  }
}

if(isLocal){
  axios.defaults.baseURL = '/api'
}
else{
  axios.defaults.baseURL = baseURL
}
const service = axios.create({
  // withCredentials: true,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "CANAL": 'pc',
    "VERSION": requestVersion,  // 版本控制
    "MACHINE": 'pc',
  }
});
service.interceptors.request.use(
  config => {
    let userInfos = JSON.parse(localStorage.getItem('userInfos'))
    if (userInfos) {
      let { accessToken } = userInfos
      if (accessToken) {
        config.headers['AT'] = accessToken;
      }
    }
    if(['/demand/template'].includes(config.url)){
      if(config.data.memberId === '-100'){
        // 需求库获取需求模板
        config.data.memberId = 1
        config.headers.CANAL = 'h5'
      }
    }
    if(['/address/address/getAllProvince','/address/address/getCityByProvinceCode','/address/address/getCityByCityCode','/upload/getTengToken','/config/getSelectByType'].includes(config.url)){
      config.headers.CANAL = 'h5'
    }
    const isTPS = talentProjectSystemPage.find((path)=>{
      return window.location.href.includes(path)
    })
    if(isTPS && getTPSToken()){
      // 是人才项目库页面且含有token
      config.headers['AT'] = getTPSToken();
    }
    else if(isTPS){
      config.headers['AT'] = ''
    }
    if(G60site && getG60Token()){
      // 是人才项目库页面且含有token
      config.headers['AT'] = getG60Token();
    }
    else if(G60site){
      config.headers['AT'] = ''
    }
    if(TanSite && getTanToken()){
      config.headers['AT'] = getTanToken();
    }
    else if(TanSite){
      config.headers['AT'] = ''
    }
    if(AISite && getAIToken()){
      config.headers['AT'] = getAIToken();
    }
    else if(AISite){
      config.headers['AT'] = ''
    }
    const isPL = PostLineSite
    if(isPL && getPLToken()){
      // 是人才项目库页面且含有token
      config.headers['AT'] = getPLToken();
    }
    else if(isPL){
      config.headers['AT'] = ''
    }

    if(noTokenApi.includes(config.url)){
      // 不需要token的接口
      config.headers['AT'] = ''
      delete config.headers['AT']
    }
    if(['/tj/company/applyForInviteJoin'].includes(config.url)){
      if(config.data.tempToken){
        config.headers['AT'] = config.data.tempToken;
      }
    }
    if(LSHTokenApi.includes(config.url)){
      // 蓝珊瑚api
      let userInfo = JSON.parse(localStorage.getItem('LSHUserInfo'))
      if (userInfo) {
        let { accessToken } = userInfo
        if (accessToken) {
          config.headers['AT'] = accessToken
        }
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let casheRequests = []   // 请求队列
let isFreshToken = true // 默认是打开

// response interceptor
service.interceptors.response.use(
  async response => {
    if(response.config.url === '/member/token/getToken' && response.data.code !== 'Sx200'){
      // ! 重新获取token的接口报错，直接退出登录
      localStorage.clear()
      setCookie('KqdInfo',{status: '0'})
      setTimeout(()=>{
        location.reload() // 刷新页面
      },2000)
      msgError('登录状态已经过期，请重新登陆');
      return Promise.reject(new Error('登录状态已经过期'));
    }
    const res = response.data;
    const isTPS = talentProjectSystemPage.find((path)=>{
      return window.location.href.includes(path)
    })
    const isPL = PostLineSite
    switch (res.code) {
      case "Dx000-000006":
        // msgError('未登录或登录信息过期，请重新登录。');
        // store.dispatch("user/logout")
        // return Promise.reject(new Error(res.message || "Error"));
        if(getRefreshToken() && isTPS) {
          if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            // 刷新token 是一个 返回promise的请求接口的方法 这个是自定义的 看你们自己的项目来定
            let result = null
            try {
              result = await refreshToken({"refreshToken": getRefreshToken(),})
              setNewToken(result.data) // 重新赋值token
              isFreshToken = true // 方法重新打开
            } catch (err) {
              console.error('token过期,重新获取错误:',err)
              removeLocalInfo()
            }
            // 重新执行 之前缓存的方法数组 使用最新的token
            casheRequests.forEach(cb => cb())
            // 重置为空 降缓存的请求方法
            casheRequests = []
            let newRes = await service(response.config)
            return Promise.resolve(newRes)
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
        }
        else if(G60site){
          if(!getG60RefreshToken()){
            return
          }
          else if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            // 刷新token 是一个 返回promise的请求接口的方法 这个是自定义的 看你们自己的项目来定
            let result = null
            try {
              result = await refreshToken({"refreshToken": getG60RefreshToken(),})
              setG60NewToken(result.data) // 重新赋值token
              isFreshToken = true // 方法重新打开
            } catch (err) {
              console.error('token过期,重新获取错误:',err)
              removeG60LocalInfo()
            }
            // 重新执行 之前缓存的方法数组 使用最新的token
            casheRequests.forEach(cb => cb())
            // 重置为空 降缓存的请求方法
            casheRequests = []
            let newRes = await service(response.config)
            return Promise.resolve(newRes)
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
        }
        else if(TanSite){
          if(!getTanRefreshToken()){
            return
          }
          else if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            // 刷新token 是一个 返回promise的请求接口的方法 这个是自定义的 看你们自己的项目来定
            let result = null
            try {
              result = await refreshToken({"refreshToken": getTanRefreshToken(),})
              setTanNewToken(result.data) // 重新赋值token
              isFreshToken = true // 方法重新打开
            } catch (err) {
              console.error('token过期,重新获取错误:',err)
              removeTanLocalInfo()
            }
            // 重新执行 之前缓存的方法数组 使用最新的token
            casheRequests.forEach(cb => cb())
            // 重置为空 降缓存的请求方法
            casheRequests = []
            let newRes = await service(response.config)
            return Promise.resolve(newRes)
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
        }
        else if(AISite){
          if(!getAIRefreshToken()){
            return
          }
          else if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            // 刷新token 是一个 返回promise的请求接口的方法 这个是自定义的 看你们自己的项目来定
            let result = null
            try {
              result = await refreshToken({"refreshToken": getAIRefreshToken(),})
              setAINewToken(result.data) // 重新赋值token
              isFreshToken = true // 方法重新打开
            } catch (err) {
              console.error('token过期,重新获取错误:',err)
              removeAILocalInfo()
            }
            // 重新执行 之前缓存的方法数组 使用最新的token
            casheRequests.forEach(cb => cb())
            // 重置为空 降缓存的请求方法
            casheRequests = []
            let newRes = await service(response.config)
            return Promise.resolve(newRes)
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
        }
        else if(getPLRefreshToken() && isPL){
          if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            // 刷新token 是一个 返回promise的请求接口的方法 这个是自定义的 看你们自己的项目来定
            let result = null
            try {
              result = await refreshToken({"refreshToken": getPLRefreshToken(),})
              setPLNewToken(result.data) // 重新赋值token
              isFreshToken = true // 方法重新打开
            } catch (err) {
              console.error('token过期,重新获取错误:',err)
              removePLLocalInfo()
            }
            // 重新执行 之前缓存的方法数组 使用最新的token
            casheRequests.forEach(cb => cb())
            // 重置为空 降缓存的请求方法
            casheRequests = []
            let newRes = await service(response.config)
            return Promise.resolve(newRes)
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
        }
        else{
          let userInfos = JSON.parse(localStorage.getItem('userInfos'))
          if (!userInfos || !userInfos.refreshToken) {
            console.error('token过期,但无缓存userInfos:',userInfos)
            removeUserInfo()
            return
          }
          else if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            store.dispatch("user/regainToken",userInfos.refreshToken).then(async ()=>{
              isFreshToken = true // 方法重新打开
              // 重新执行 之前缓存的方法数组 使用最新的token
              casheRequests.forEach(cb => cb())
              // 重置为空 降缓存的请求方法
              casheRequests = []
              let newRes = await service(response.config)
              return Promise.resolve(newRes)
            }).catch(err=>{
              console.error('token过期,重新获取错误:',err)
            })
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
          return;
        }
      case "Dx000-000003":
        // msgError('未登录或登录信息过期，请重新登录。');
        // store.dispatch("user/logout")
        // return Promise.reject(new Error(res.message || "Error"));
        if(G60site){
          if(!getG60RefreshToken()){
            return
          }
          else if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            // 刷新token 是一个 返回promise的请求接口的方法 这个是自定义的 看你们自己的项目来定
            let result = null
            try {
              result = await refreshToken({"refreshToken": getG60RefreshToken(),})
              setG60NewToken(result.data) // 重新赋值token
              isFreshToken = true // 方法重新打开
            } catch (err) {
              console.error('token过期,重新获取错误:',err)
              removeG60LocalInfo()
            }
            // 重新执行 之前缓存的方法数组 使用最新的token
            casheRequests.forEach(cb => cb())
            // 重置为空 降缓存的请求方法
            casheRequests = []
            let newRes = await service(response.config)
            return Promise.resolve(newRes)
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
        }
        else if(TanSite){
          if(!getTanRefreshToken()){
            return
          }
          else if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            // 刷新token 是一个 返回promise的请求接口的方法 这个是自定义的 看你们自己的项目来定
            let result = null
            try {
              result = await refreshToken({"refreshToken": getTanRefreshToken(),})
              setTanNewToken(result.data) // 重新赋值token
              isFreshToken = true // 方法重新打开
            } catch (err) {
              console.error('token过期,重新获取错误:',err)
              removeTanLocalInfo()
            }
            // 重新执行 之前缓存的方法数组 使用最新的token
            casheRequests.forEach(cb => cb())
            // 重置为空 降缓存的请求方法
            casheRequests = []
            let newRes = await service(response.config)
            return Promise.resolve(newRes)
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
        }
        else if(AISite){
          if(!getAIRefreshToken()){
            return
          }
          else if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            // 刷新token 是一个 返回promise的请求接口的方法 这个是自定义的 看你们自己的项目来定
            let result = null
            try {
              result = await refreshToken({"refreshToken": getAIRefreshToken(),})
              setAINewToken(result.data) // 重新赋值token
              isFreshToken = true // 方法重新打开
            } catch (err) {
              console.error('token过期,重新获取错误:',err)
              removeAILocalInfo()
            }
            // 重新执行 之前缓存的方法数组 使用最新的token
            casheRequests.forEach(cb => cb())
            // 重置为空 降缓存的请求方法
            casheRequests = []
            let newRes = await service(response.config)
            return Promise.resolve(newRes)
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
        }
        else{
          let userInfos = JSON.parse(localStorage.getItem('userInfos'))
          if (!userInfos || !userInfos.refreshToken) {
            console.error('token过期,但无缓存userInfos:',userInfos)
            removeUserInfo()
            return
          }
          else if(isFreshToken){ //token可以刷新 一次并发只能有一个刷新
            isFreshToken = false // 把开关关闭
            store.dispatch("user/regainToken",userInfos.refreshToken).then(async ()=>{
              isFreshToken = true // 方法重新打开
              // 重新执行 之前缓存的方法数组 使用最新的token
              casheRequests.forEach(cb => cb())
              // 重置为空 降缓存的请求方法
              casheRequests = []
              let newRes = await service(response.config)
              return Promise.resolve(newRes)
            }).catch(err=>{
              console.error('token过期,重新获取错误:',err)
            })
          }
          else {
            // token 正在刷新中 其他的请求先放在队列中
            return new Promise(resolve => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              casheRequests.push(() => {
                resolve(service(response.config))
              })
            })
          }
          return;
        }
      case "LxAPI-CCCCCC":
        if(res.message === '专家管理权限状态不正确') return Promise.reject(res);
        msgError(res.message);
        return Promise.reject(new Error(res.message || "Error"));
      case "Sx200":
        if(response.data.message === '加密成功'){
          // 进行解密
          const timeArray = response.data.timestamp
          const date = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4], timeArray[5]);
          // 获取时间戳
          const timestamp = date.getTime();
          res.data = JSON.parse(decryptByDES(res.data,response.config.url + timestamp))
        }
        return res
      case "LxAPI-40000":
        msgError('请绑定手机！')
        return Promise.reject(new Error("bindPhone"));
      case "LxAPI-40001":
        msgError('请重新扫码！')
        return Promise.reject(new Error("codeError"));
      case "LxAPI-000581":  // 发送邮件到次数
        msgError(res.message);
        return Promise.reject(new Error("LxAPI-000581"));
      case "TjAPI-200005":  // 探角无订阅错误
        msgError(res.message);
        return Promise.reject(new Error("TjAPI-200005"));
      case "TjAPI-000015":
        return Promise.reject(new Error("TjAPI-000015"));
      case "TjAPI-000006":
        return Promise.reject(res);
      case "LxAPI-060007":
        return Promise.reject(res);
      default:
        msgError(res.message);
        return Promise.reject(new Error(res.message || "Error"));
    }

  },
  error => {
    console.error(error)
    return Promise.reject(error);
  }
);
const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    service
      .get(url, {params: params})
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.error(err)
        reject(err)
      });
  });
};

const post = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    service
      .post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.error(err)
        reject(err)
        // ElMessage.error(err.message);
      });
  });
};
const del = (url, params = {}) => {
  return new Promise(resolve => {
    service
      .delete(url, {
        data: params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.error(err)
      });
  });
};

const put = (url, params = {}) => {
  return new Promise(resolve => {
    service
      .put(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.error(err)
      });
  });
};
export {service, post, get, put, del};
// export default service;
