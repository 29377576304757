<template>
  <el-dialog v-model="showWindow" :show-close="false" :close-on-press-escape="false" :width="'660px'" :custom-class="'dialog-no-title dialog-transparent'" :close-on-click-modal="false">
    <div class="company-edit-window" v-loading="loading">
      <p class="p-0">企业资料编辑</p>
      <img src="@/assets/images/Tan/pop/companyEdit/close.png" class="close" @click="closeEdit" />
      <div class="title-line">
        企业基本信息
      </div>
      <div class="form-item">
        <div class="item-title">
          企业logo：
        </div>
        <div class="right">
          <div class="upload" v-if="!form.file.url" v-loading="uploading">
            <el-upload
              action="#"
              :accept="'image/*'"
              v-model:file-list="form.file.files"
              :http-request="handleUpload"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :before-upload="beforeUploadFile"
              class="my-upload"
              :limit="1">
              <div class="upload-container">
                <img src="@/assets/images/Tan/pop/vip/upload.png" />
              </div>
            </el-upload>
          </div>
          <div class="upload" v-else v-loading="uploading">
            <img class="result" :src="form.file.url"/>
            <img class="delete" @click="handleRemove" src="@/assets/images/Tan/pop/vip/delete.png"/>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          企业名称：
        </div>
        <div class="right">
          <el-input v-model="form.name" style="width: 340px;" placeholder="请输入企业全称" maxlength="20"/>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          企业类型：
        </div>
        <div class="right">
          <el-select v-model="form.type" placeholder="请选择" style="width: 340px;">
            <el-option
              v-for="(item,index) in typeList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          行业领域：
        </div>
        <div class="right">
          <MultiSelection :selections="multiIndustry" :placeholder="'请选择您主要关注的投资领域，最多5个'" :max="5" :ref="'industrys'" class="no-wrap" style="width: 340px;" :value="form?.industry" @sendValue="sendIndustry" v-if="!reInit"></MultiSelection>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          所在地区：
        </div>
        <div class="right">
          <el-cascader ref="cascaderAddr" :props="props" style="width: 340px;" :placeholder="'请选择'" @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange" v-model="form.address"></el-cascader>
        </div>
      </div>
      <div class="title-line">
        企业技术信息
      </div>
      <div class="form-item">
        <div class="item-title">
          企业研发领域：
        </div>
        <div class="right">
          <el-select v-model="form.tech" multiple style="width: 340px;" placeholder="请输入，回车确认" clearable filterable allow-create default-first-option :multiple-limit="5">
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          主要产品服务：
        </div>
        <div class="right">
          <el-input v-model="form.service" style="width: 340px;" placeholder="请输入主要产品服务" maxlength="50"/>
        </div>
      </div>
      <div class="form-item">
        <div class="item-title">
          核心发展阶段：
        </div>
        <div class="right">
          <el-select v-model="form.step" placeholder="请选择" style="width: 340px;">
            <el-option
              v-for="(item,index) in stepList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="form-item">
        <div class="item-title">
          关注科研领域：
        </div>
        <div class="right">
          <el-select
            v-model="form.focus"
            filterable
            remote
            multiple
            placeholder="请搜索并选择领域"
            remote-show-suffix
            :remote-method="remoteMethod"
            :loading="loadingRemote"
            style="width: 340px">
            <el-option
              v-for="(item) in remoteList"
              :key="item.id"
              :label="item.associationText"
              :value="item.associationText"
            />
          </el-select>
        </div>
      </div> -->
      <div class="buttons">
        <div class="cancel" @click="closeEdit">
          取消
        </div>
        <div class="submit" @click="submit" v-loading="editing">
          提交
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import MultiSelection from '@/components/MultiSelection/MultiSelection';
import { mapGetters } from "vuex";
import { uploadTypeImage,cosByUploadType, } from "@/api/upload";
import { bucketMap,staticPath } from "@/utils/consts";
import { ElMessage } from 'element-plus';
import { randomFilename } from "@/utils/time";
import { getprovince, getcity, getarea } from "@/api/demand";
import { getSelectFatherSonByType } from "@/api/config";
import { getTanCompanyInfo,editTanCompanyInfo } from "@/api/Tan/company";
import { getTanId,getTanLocalInfo,setTanLocalInfo } from "@/utils/Tan/login";
import { associationSearch } from '@/api/search';

export default{
  components:{
    MultiSelection,
  },
  data(){
    return{
      editing: false,
      loadingRemote: false,
      remoteList: [],
      loading: false,
      reInit: false,
      showWindow: false,
      uploading: false,
      typeList: [],
      multiIndustry: [],
      stepList: [],
      form:{
        file: {
          url: '',
          files: [],
        },
        name: '',
        type: '',
        industry: '',
        address: [],
        service: '',
        tech: '',
        step: '',
        focus: '',
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    code: item.provinceCode,
                    value: item.provinceName,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.data.code).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    code: item.cityCode,
                    value: node.data.value === item.cityName ? item.cityName + '_' : item.cityName,
                    label: item.cityName,
                    leaf: false
                  })
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.data.code).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    code: item.areaCode,
                    value: item.areaName,
                    label: item.areaName,
                    leaf: true
                  })
                }
                resolve(areas);
              });
            }
          }, 100);
        }
      },
    }
  },
  computed:{
    ...mapGetters([
      "TanCompanyEditPopShow",
    ]),
  },
  watch:{
    TanCompanyEditPopShow(val){
      this.showWindow = val
    },
    showWindow(val){
      if(!val){
        this.$store.dispatch('Tan/setCompanyEditShow',false)
      }
      else{
        this.getCompanyDetail()
      }
    },
  },
  mounted(){
    this.getSelections()
  },
  methods:{
    submit(){
      if(!this.form.name){
        ElMessage.warning('请输入企业全称')
        return
      }
      else if(!this.form.type){
        ElMessage.warning('请选择企业类型')
        return
      }
      else if(this.form.industry?.length === 0){
        ElMessage.warning('请选择行业领域')
        return
      }
      else if(this.form.address?.length === 0){
        ElMessage.warning('请选择所在地区')
        return
      }
      else if(this.form.tech?.length === 0){
        ElMessage.warning('请选择企业研发领域')
        return
      }
      else if(!this.form.service){
        ElMessage.warning('请输入主要产品服务')
        return
      }
      else if(!this.form.step){
        ElMessage.warning('请选择核心发展阶段')
        return
      }
      // else if(this.form.focus?.length === 0){
      //   ElMessage.warning('请选择搜索选择关注科研领域')
      //   return
      // }
      this.editing = true
      editTanCompanyInfo({
        "address": this.form.address.map((item)=>item.replace('_','')).join(','),
        "area": this.form.industry,
        "companyName": this.form.name,
        "companyType": this.form.type,
        "coreTechnologyAreas": this.form.tech?.length > 0 ? this.form.tech.join(',') : '',
        "interestAreas": this.form.focus?.length > 0 ? this.form.focus.join(',') : '',
        "logo": this.form.file.url,
        "mainProductService": this.form.service || '',
        "memberId": getTanId(),
        "technologyPhase": this.form.step || '',
        "tjCompanyId": getTanLocalInfo()?.tjInfo.tjCompanyId,
      }).then(res=>{
        if(res.data){
          ElMessage.success('修改成功')
          let info = getTanLocalInfo()
          info.tjCompanyInfo.companyName = this.form.name
          info.tjCompanyInfo.logo = this.form.file.url
          setTanLocalInfo(info) // 修改存入缓存
          this.closeEdit()
        }
      }).finally(()=>{
        this.editing = false
      })
    },
    remoteMethod(string){
      if(this.loadingRemote || !string || string?.length < 2 || !getTanLocalInfo()?.memberInfo?.id) return
      this.loadingRemote = true
      associationSearch({
        "keyword": string,
        "searchType": 7,
      }).then(res=>{
        this.remoteList = res.data.filter((item)=>!item.expertName)
      }).finally(()=>{
        this.loadingRemote = false
      })
    },
    getCompanyDetail(){
      if(this.loading) return
      this.loading = true
      this.reInit = true
      getTanCompanyInfo(getTanId()).then((res)=>{
        const info = res.data
        let logo = ''
        if(info.logo){
          logo = info.logo.includes('http') ? info.logo : staticPath + info.logo
        }
        this.form.file.url = logo
        this.form.name = info.companyName
        this.form.type = info.companyType
        this.form.industry = info.area
        this.form.service = info.mainProductService
        this.form.tech = info.coreTechnologyAreas ? info.coreTechnologyAreas.split(',') : ''
        this.form.step = info.technologyPhase
        this.form.focus = info.interestAreas ? info.interestAreas.split(',') : ''
        if(info.address){
          let arr = info.address.split(',')
          if(arr[0] === arr[1]){
            arr[1] += '_'
          }
          this.form.address = arr
        }
        else{
          this.form.address = []
        }
      }).finally(()=>{
        this.loading = false
        this.reInit = false
      })
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    getSelections(){
      getSelectFatherSonByType(6).then(res=>{
        this.multiIndustry = res.data.map((item)=>{
          item.child = item.child?.map((jtem)=>{
            jtem.id = jtem.val
            return jtem
          })
          item.id = item.val
          return item
        })
      })
      getSelectFatherSonByType(10032).then(res=>{
        this.typeList = res.data.map(item=>item.val)
      })
      getSelectFatherSonByType(10033).then(res=>{
        this.stepList = res.data.map(item=>item.val)
      })
    },
    resetForm(){
      this.reInit = true
      setTimeout(()=>{
        this.reInit = false
      },300)
    },
    sendIndustry(val){
      this.form.industry = val.map(item=>{
        return item[item.length - 1]
      }).join(',')
    },
    beforeUploadFile(file){
      // 上传文件
      if(file.size > 5 * 1024 * 1024){
        // 大小判断 5M
        ElMessage.warning('上传图片大小过大，不能超过5M!')
        return false
      }
    },
    handleExceed(){
      ElMessage.warning('最多只能上传1个图片')
    },
    handleRemove(){
      this.form.file.files = []
      this.form.file.url = ''
    },
    handleUpload(val){
      // 先登录
      this.directUploadToTen(val.file).then(res=>{
        this.form.file.url = 'https://' + res.Location
        this.form.file.files = [{
          name: val.file.name,
          url: 'https://' + res.Location,
        },]
      })
    },
    directUploadToTen(file){
      let self = this
      let cos = cosByUploadType(uploadTypeImage)
      let bucket = bucketMap.image
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket,
            Region: "ap-shanghai",
            Key: randomFilename(),
            StorageClass: "STANDARD",
            Body: file,
            onProgress: function (progressData) {
              const percentage = parseInt(progressData.percent * 100);
              if (percentage === 100){
                self.uploading = false;
                ElMessage.success('上传成功!')
              }
              else self.uploading = true
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
    closeEdit(){
      this.showWindow = false
    },
  }
}
</script>

<style lang="scss" scoped>
.company-edit-window{
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  padding-top: 21px;
  padding-bottom: 47px;
  position: relative;
  .close{
    width: 14px;
    height: 14px;
    position: absolute;
    right: 23px;
    cursor: pointer;
    top: 23px;
  }
  .p-0{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    padding-left: 22px;
    padding-bottom: 13px;
  }
  .title-line{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #111111;
    width: 100%;
    background: #EFF6FF;
    box-sizing: border-box;
    border-left: 4px solid $--color-theme;
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    margin-bottom: 20px;
  }
  .form-item{
    width: 100%;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    :deep(.el-select__tags-text){
      font-size: 14px;
    }
    .item-title{
      width: 98px;
      height: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #111111;
      line-height: 14px;
      text-align: right;
      padding-top: 13px;
    }
    .right{
      width: 340px;
    }
    .upload{
      width: 120px;
      height: 70px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      .result{
        width: 120px;
        height: 70px;
      }
      .delete{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-color: #999999ca;
        padding: 3px;
        border-top-right-radius: 10px;
      }
      .upload-container{
        width: 120px;
        height: 70px;
        background: #F4F7FD;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img{
          width: 46px;
          height: 40px;
        }
      }
    }
  }
  .buttons{
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .cancel{
      width: 130px;
      height: 32px;
      background: #C6CAD3;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      margin: 0 10px;
    }
    .submit{
      margin: 0 10px;
      width: 130px;
      height: 32px;
      background: $--color-theme;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
.no-wrap{
  :deep(.el-select__tags) {
    white-space: nowrap;
    overflow: hidden;
  }
  :deep(.el-cascader__tags){
    flex-wrap: nowrap !important;
    overflow: hidden;
  }
}
</style>